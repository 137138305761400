@font-face {
    font-family: "poppins";
    src: url("../Assets/Poppins-Medium.ttf");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    scrollbar-width: thin;
    scrollbar-color: #006c21 #003710;

    &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #006c21;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: darken($color: #006c21, $amount: 3%);
    }
}

.homepage {
    color: white;
    min-height: 400px;
    min-height: 600px;
    height: 100vh;
    background: url("../Assets/img1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
        background: rgba(0, 0, 0, .7);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .middle-container {
        max-width: 40%;
        width: 100%;
        background: url("../Assets/img2.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 20px #000000;
    }

    .buttons-container {
        position: absolute;
        bottom: 10%;
        left: 0;


        @media screen and (max-width: 991px) {
            bottom: 30%;
        }

        .button-indiv {
            border: 0;
            color: white;
            width: fit-content;
            cursor: pointer;
            margin-inline: 1rem;
            padding: 0;

            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0, 0, 0, .5);
            transition: .3s ease-in-out;

            &:focus,
            &:focus-visible {
                outline: unset;
                border: 0;
            }

            @media screen and (max-width: 330px) {
                margin-inline: .5rem;
            }

            &:hover {
                transition: .3s ease-in-out;
                box-shadow: 0 0 20px rgba(0, 0, 0, 1);
            }
        }

        .golden-ring {
            max-width: 120px;
            width: 100%;
            height: auto;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            font-family: "poppins";

            @media screen and (max-width: 330px) {
                font-size: 20px;
            }
        }
    }

    .disabled-btn {
        filter: grayscale(1);
    }

    .control-btn {
        color: white;
        background: url("../Assets/golden-ring.png");
        background-size: 200px;
        background-position: center;
        padding: .5rem 1rem;
        border: 0;
        border-radius: 16px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 1), 0 0 10px rgba(0, 0, 0, 1);
        display: flex;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: black;
            width: 24px;
            height: auto;
        }
    }


    .close-btn {
        position: fixed;
        top: 1.5rem;
        z-index: 3;
        right: 1.5rem;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: url("../Assets/golden-ring.png") center center;
        border: 0;
        color: white;
        font-weight: 700;
        box-shadow: 0 0 10px rgb(0, 0, 0), 0 0 10px rgb(0, 0, 0);

        &:focus,
        &:focus-visible {
            outline: unset;
            border: 0;
        }

        @media screen and (max-width: 767px) {
            width: 50px;
            height: 50px;
            font-size: 13px;
        }
    }

    .pdf-container {
        position: absolute;
        top: 1rem;
        left: 1rem;
        right: 1rem;
        max-width: 1200px;
        margin: auto;
        box-shadow: 0 0 10px rgba(0, 0, 0, 1), 0 0 10px rgba(0, 0, 0, 1), 0 0 10px rgba(0, 0, 0, 1);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: auto;
        background: rgba(0, 0, 0, .2);
        backdrop-filter: blur(10px);
        max-height: 90vh;
        z-index: 1;
        padding-bottom: 4rem;



    }

    .lds-roller {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
    }

    .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #fff;
        margin: -4px 0 0 -4px;
    }

    .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
    }

    .lds-roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
    }

    .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
    }

    .lds-roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
    }

    .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
    }

    .lds-roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
    }

    .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
    }

    .lds-roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
    }

    .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
    }

    .lds-roller div:nth-child(5):after {
        top: 71px;
        left: 32px;
    }

    .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
    }

    .lds-roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
    }

    .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
    }

    .lds-roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
    }

    .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
    }

    .lds-roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
    }

    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

}